export const ShareIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ flexShrink: 0 }}
  >
    <path
      d="M6.76616 2.5V4.16667H2.56896V13.3333H11.8028V9.16667H13.4817V14.1667C13.4817 14.4 13.4005 14.5972 13.2383 14.7583C13.076 14.9194 12.8773 15 12.6422 15H1.72952C1.49447 15 1.29581 14.9194 1.13351 14.7583C0.971221 14.5972 0.890076 14.4 0.890076 14.1667V3.33333C0.890076 3.1 0.971221 2.90278 1.13351 2.74167C1.29581 2.58055 1.49447 2.5 1.72952 2.5H6.76616ZM16 0V7.5L12.8101 4.33333L7.77349 9.33333L6.59827 8.16667L11.6349 3.16667L8.44504 0H16Z"
      fill="white"
    />
  </svg>
);
