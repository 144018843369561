export const CosmosIcon = ({
  color = "black",
}: {
  color?: string;
} = {}) => (
  <svg
    width="117"
    height="22"
    viewBox="0 0 117 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3385 15.8699L11.7507 14.28C11.1477 14.8364 10.5381 15.2771 9.92144 15.6018C9.30532 15.9262 8.56823 16.0884 7.71081 16.0884C7.04078 16.0884 6.42112 15.9595 5.85165 15.701C5.28219 15.4426 4.78974 15.0884 4.3743 14.6377C3.95886 14.1873 3.63752 13.6607 3.40947 13.058C3.18168 12.4553 3.06806 11.8091 3.06806 11.1206C3.06806 10.4316 3.18168 9.7889 3.40947 9.19262C3.63752 8.59666 3.95886 8.07673 4.3743 7.63277C4.78974 7.18907 5.28219 6.8381 5.85165 6.57965C6.42112 6.32146 7.04078 6.19223 7.71081 6.19223C8.51457 6.19223 9.23157 6.35122 9.86128 6.66919C10.4912 6.98693 11.0875 7.40435 11.6499 7.92103L13.2379 6.11286C12.903 5.79489 12.548 5.50345 12.1727 5.23829C11.7976 4.9734 11.389 4.74471 10.9467 4.55273C10.5045 4.3605 10.0188 4.2117 9.48938 4.1058C8.96016 3.99962 8.37383 3.94653 7.73084 3.94653C6.65893 3.94653 5.68082 4.13555 4.79652 4.51305C3.91195 4.89055 3.14832 5.4072 2.50511 6.063C1.86188 6.7188 1.36292 7.4837 1.00768 8.35829C0.652445 9.23262 0.475098 10.1666 0.475098 11.1603C0.475098 12.167 0.65597 13.1076 1.01772 13.9822C1.37946 14.8562 1.87869 15.6147 2.51513 16.2573C3.15158 16.8997 3.90517 17.4065 4.77618 17.7776C5.64719 18.1486 6.59874 18.3339 7.63029 18.3339C8.28706 18.3339 8.8866 18.2775 9.42922 18.165C9.97191 18.0524 10.4711 17.8869 10.9266 17.6684C11.3822 17.4496 11.8077 17.1912 12.2031 16.8933C12.5981 16.5952 12.9767 16.2539 13.3385 15.8699Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.2308 7.88535C45.2308 7.42259 45.4307 7.02936 45.8305 6.70571C46.2302 6.3815 46.7901 6.21967 47.5097 6.21967C48.1494 6.21967 48.7824 6.34218 49.4087 6.58667C50.0352 6.83116 50.6682 7.18481 51.3076 7.64724L52.627 5.80344C51.9077 5.23501 51.1379 4.80221 50.3183 4.50502C49.4984 4.20756 48.5758 4.05884 47.5496 4.05884C46.8568 4.05884 46.217 4.1546 45.634 4.3464C45.0476 4.53793 44.5482 4.81211 44.1315 5.16895C43.715 5.52605 43.3884 5.95217 43.1487 6.44784C42.9122 6.94351 42.7921 7.49537 42.7921 8.10336C42.7921 8.75096 42.8955 9.29963 43.102 9.74899C43.3086 10.1984 43.6118 10.5817 44.005 10.899C44.398 11.2162 44.8878 11.4805 45.4673 11.6921C46.0504 11.9037 46.7136 12.095 47.4631 12.267C48.1163 12.4256 48.6456 12.5776 49.0556 12.7228C49.4621 12.8683 49.7819 13.0237 50.0185 13.1887C50.2514 13.3541 50.4116 13.5357 50.4983 13.7339C50.5848 13.9323 50.6279 14.1568 50.6279 14.408C50.6279 14.9502 50.4049 15.3799 49.9586 15.6968C49.5119 16.0143 48.9089 16.1727 48.1494 16.1727C47.2699 16.1727 46.4835 16.0172 45.7906 15.7067C45.0975 15.3961 44.4114 14.9502 43.7315 14.3684L42.2525 16.1133C43.0921 16.8666 44.005 17.425 44.991 17.7886C45.9773 18.1522 47.0099 18.3338 48.0893 18.3338C48.8223 18.3338 49.4955 18.2412 50.1086 18.0564C50.718 17.871 51.2445 17.6 51.6776 17.2432C52.114 16.8863 52.4538 16.4503 52.7003 15.9348C52.9436 15.4194 53.0668 14.8312 53.0668 14.1702C53.0668 13.5887 52.9702 13.083 52.7769 12.6535C52.5835 12.2239 52.2971 11.8475 51.9241 11.5233C51.5508 11.1996 51.0778 10.9252 50.5114 10.7005C49.9453 10.4761 49.2922 10.2712 48.5591 10.086C47.8895 9.9274 47.3398 9.77527 46.9035 9.62997C46.4703 9.48474 46.1305 9.32611 45.8805 9.15414C45.634 8.98231 45.4643 8.79727 45.3708 8.59909C45.2777 8.40053 45.2308 8.16274 45.2308 7.88535Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.5947 8.08172V17.9964H75.0369V4.17114H72.4139L68.1499 10.7086L63.8855 4.17114H61.2629V17.9964H63.7053V8.12113L68.0894 14.5994H68.17L72.5947 8.08172Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.5576 11.1603C95.5576 11.836 95.4405 12.4751 95.207 13.0781C94.9722 13.6808 94.6483 14.2038 94.234 14.6476C93.8191 15.0914 93.3209 15.4426 92.7388 15.701C92.1573 15.9595 91.5186 16.0885 90.8233 16.0885C90.1274 16.0885 89.4855 15.9559 88.8975 15.6911C88.3089 15.4259 87.8042 15.0683 87.3827 14.6181C86.9619 14.1675 86.6341 13.6409 86.4 13.0381C86.1659 12.4355 86.0488 11.796 86.0488 11.1206C86.0488 10.4447 86.1659 9.80555 86.4 9.20283C86.6341 8.59985 86.958 8.07673 87.373 7.63284C87.7873 7.18907 88.2855 6.83811 88.8669 6.57965C89.4491 6.32147 90.0877 6.19224 90.783 6.19224C91.4783 6.19224 92.1209 6.32468 92.7095 6.58984C93.2974 6.85474 93.8022 7.21236 94.2236 7.66282C94.6444 8.11296 94.9722 8.63978 95.207 9.24257C95.4405 9.84522 95.5576 10.4847 95.5576 11.1603ZM98.1455 11.1206C98.1455 10.1401 97.9653 9.21278 97.6044 8.33819C97.2427 7.46413 96.7413 6.70217 96.1 6.05307C95.4574 5.40372 94.6886 4.89055 93.7924 4.51305C92.8961 4.13555 91.9069 3.94653 90.8233 3.94653C89.7398 3.94653 88.7505 4.13877 87.8543 4.52297C86.958 4.90718 86.1828 5.42704 85.5272 6.08284C84.8716 6.73864 84.3636 7.5038 84.0026 8.37819C83.6417 9.25245 83.4609 10.1799 83.4609 11.1603C83.4609 12.1405 83.6417 13.0676 84.0026 13.9423C84.3636 14.8168 84.8651 15.5785 85.507 16.2276C86.1483 16.8766 86.9177 17.3901 87.814 17.7676C88.7102 18.1451 89.7001 18.3339 90.783 18.3339C91.8659 18.3339 92.8558 18.1416 93.7521 17.7577C94.6483 17.3735 95.4242 16.8536 96.0798 16.1976C96.7348 15.542 97.2427 14.7768 97.6044 13.9025C97.9653 13.0282 98.1455 12.1009 98.1455 11.1206Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M108.41 7.88535C108.41 7.42259 108.609 7.02936 109.009 6.70571C109.409 6.3815 109.969 6.21967 110.689 6.21967C111.328 6.21967 111.961 6.34218 112.587 6.58667C113.214 6.83116 113.847 7.18481 114.486 7.64724L115.806 5.80344C115.086 5.23501 114.316 4.80221 113.497 4.50502C112.677 4.20756 111.755 4.05884 110.728 4.05884C110.035 4.05884 109.396 4.1546 108.812 4.3464C108.226 4.53793 107.727 4.81211 107.31 5.16895C106.893 5.52605 106.567 5.95217 106.327 6.44784C106.091 6.94351 105.971 7.49537 105.971 8.10336C105.971 8.75096 106.075 9.29963 106.281 9.74899C106.488 10.1984 106.791 10.5817 107.184 10.899C107.576 11.2162 108.066 11.4805 108.646 11.6921C109.229 11.9037 109.892 12.095 110.642 12.267C111.295 12.4256 111.825 12.5776 112.234 12.7228C112.641 12.8683 112.96 13.0237 113.197 13.1887C113.43 13.3541 113.59 13.5357 113.677 13.7339C113.764 13.9323 113.807 14.1568 113.807 14.408C113.807 14.9502 113.583 15.3799 113.137 15.6968C112.69 16.0143 112.088 16.1727 111.328 16.1727C110.449 16.1727 109.662 16.0172 108.97 15.7067C108.276 15.3961 107.59 14.9502 106.91 14.3684L105.431 16.1133C106.27 16.8666 107.184 17.425 108.17 17.7886C109.156 18.1522 110.188 18.3338 111.268 18.3338C112.001 18.3338 112.674 18.2412 113.287 18.0564C113.897 17.871 114.423 17.6 114.856 17.2432C115.293 16.8863 115.633 16.4503 115.879 15.9348C116.122 15.4194 116.245 14.8312 116.245 14.1702C116.245 13.5887 116.149 13.083 115.955 12.6535C115.762 12.2239 115.475 11.8475 115.102 11.5233C114.729 11.1996 114.257 10.9252 113.69 10.7005C113.124 10.4761 112.471 10.2712 111.738 10.086C111.068 9.9274 110.519 9.77527 110.082 9.62997C109.649 9.48474 109.31 9.32611 109.059 9.15414C108.812 8.98231 108.643 8.79727 108.549 8.59909C108.456 8.40053 108.41 8.16274 108.41 7.88535Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.093 6.93892L23.4006 13.5472C23.3143 13.3818 23.2365 13.2096 23.1667 13.0309C22.9329 12.4305 22.816 11.7934 22.816 11.1206C22.816 10.4473 22.9329 9.81048 23.1667 9.21003C23.4006 8.60939 23.7247 8.08823 24.1391 7.6459C24.5535 7.20389 25.0515 6.85425 25.6328 6.59678C26.2144 6.33956 26.8525 6.21082 27.5475 6.21082C28.2427 6.21082 28.8843 6.34276 29.4721 6.60693C29.6907 6.70495 29.8976 6.81552 30.093 6.93892ZM29.5024 15.6837C28.9208 15.9412 28.2827 16.0696 27.5877 16.0696C26.8926 16.0696 26.251 15.9377 25.6629 15.6738C25.4445 15.5755 25.2377 15.465 25.0426 15.3412L31.7347 8.73355C31.8209 8.89862 31.8988 9.07065 31.9686 9.24958C32.2023 9.85002 32.3192 10.487 32.3192 11.1601C32.3192 11.8332 32.2023 12.47 31.9686 13.0707C31.7346 13.6711 31.4106 14.1922 30.9961 14.6343C30.5818 15.0763 30.084 15.4262 29.5024 15.6837ZM30.515 17.7326C31.4106 17.3499 32.1858 16.832 32.8407 16.1783C33.4956 15.5253 34.0033 14.763 34.3643 13.892C34.7253 13.021 34.9058 12.0971 34.9058 11.1206C34.9058 10.1439 34.7253 9.21992 34.3643 8.34865C34.1502 7.8321 33.8867 7.35504 33.574 6.91732L37.9268 2.61945L36.1971 0.911621L31.8312 5.22257C31.4392 4.95868 31.0137 4.73058 30.555 4.538C29.6593 4.16193 28.6704 3.97363 27.5877 3.97363C26.505 3.97363 25.516 4.16514 24.6204 4.54788C23.7247 4.93063 22.9494 5.44853 22.2945 6.10185C21.6396 6.75517 21.1316 7.51751 20.7707 8.38845C20.4099 9.25953 20.2295 10.1833 20.2295 11.1601C20.2295 12.1366 20.4099 13.0602 20.7707 13.9316C20.985 14.4484 21.2485 14.9254 21.5612 15.3634L17.2087 19.6608L18.9383 21.3686L23.304 17.0579C23.696 17.3218 24.1215 17.5499 24.5802 17.7425C25.476 18.1186 26.465 18.3066 27.5475 18.3066C28.6302 18.3066 29.6192 18.1151 30.515 17.7326Z"
      fill={color}
    />
  </svg>
);
